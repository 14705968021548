import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {StipendStatCard} from '@compt/pages/employee-stipends/my-stipends-page/components/stipend-stat-card';
import {Allotment} from '@compt/types/allotments';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptDropDown} from '@compt/common/forms/compt-dropdown/compt-dropdown';
import {AllotmentDropdownOption, MyStipendsController} from './my-stipends.controller';
import {useCreateStipendExpenseMutation} from '@compt/app/services/api/stipend-expenses-slice';
import {ComptSaveClaimSidePanel} from '@compt/pages/employee-stipends/components/compt-save-claim-side-panel/compt-save-claim-side-panel.container';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {AllotmentCard} from '@compt/pages/employee-stipends/my-stipends-page/components/allotment-card';
import {MobileAllotmentCard} from '@compt/pages/employee-stipends/my-stipends-page/components/mobile-allotment-card';
import {ComptSelectCategorySidePanel} from '../components/compt-select-category-side-panel/compt-select-category-side-panel.container';
import {PerkCategory} from '@compt/types/perk-category';
import {FullAllotmentDescriptionModal} from '@compt/pages/employee-stipends/components/full-allotment-description-modal';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {NoPermissionsPage} from '@compt/pages/no-permissions-page/no-permissions-page';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {
  usePostToMSTeamsMutation,
  usePostToSlackMutation,
} from '@compt/app/services/api/notifications-slice';
import {useUpdateUserTagMutation} from '@compt/app/services/api/accounts-slice';
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useGetHomePath} from '@compt/utils/home-path-helper';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {ComptWarningBanner} from '@compt/common/compt-warning-banner/compt-warning-banner';
import {useGetDraftExpensesQuery} from '@compt/app/services/api/draft-expense-slice';
import {usePaginationQuery} from '@compt/pages/admin-pages/admin-review-programs/format-query-helper';
import {MAX_15_PAGE_LIMIT} from '@compt/constants';
import {ComptLink} from '@compt/common/compt-link/compt-link';

export const MyStipendsPage = () => {
  const {scrollToCardOnSelect, transformAllotmentToOption, onSubmitClaim} =
    new MyStipendsController();

  const isMobileView = useIsMobileView();

  const [open, setOpen] = useState(false);
  const [openSelectCategorySidePanel, setOpenSelectCategorySidePanel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<PerkCategory>();
  const [selectedAllotment, setSelectedAllotment] = useState<Allotment>();
  const [checkHelpScoutMessages, setCheckHelpScoutMessages] = useState(true);
  const [allotmentToDescribe, setAllotmentToDescribe] = useState<Allotment | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const isStipendEligible = useHasRequiredRole([USER_ROLES.stipendEligible]);
  const homePath = useGetHomePath();
  const navigate = useNavigate();

  // Session queries
  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const hasSlackToken = session.data?.has_slack_token;
  const userCountryCode = session.data?.country as SupportedCountriesType;

  // Allotment queries
  const allotmentQuery = useGetAllotmentsQuery(userId ?? skipToken);
  const allotments = allotmentQuery.data?.allotments;
  const categoryCustomizations = allotmentQuery.data?.perk_category_customizations;
  const allotmentOptions = transformAllotmentToOption(allotments);
  const stipendsStats = allotmentQuery.data?.stipends_stats;
  const allotmentsWithBalance = useMemo(
    () =>
      allotments ? allotments.filter((allotment) => allotment.balance_amount > 0) : allotments,
    [allotments],
  );
  const paginationQuery = usePaginationQuery(MAX_15_PAGE_LIMIT);
  const draftExpensesQuery = useGetDraftExpensesQuery({
    filter: {
      ...paginationQuery,
      limit: paginationQuery,
    },
  });
  const draftExpensesCount = draftExpensesQuery?.data?.count ?? 0;

  // Company queries
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;
  const companyHasSlack = companyQuery.data?.has_slack;
  const hasMsTeams = companyQuery.data?.has_ms_teams;
  const customReimbursementInfo = companyQuery.data?.reimbursement_info;

  const [createStipend, {isLoading}] = useCreateStipendExpenseMutation();
  const [updateUserTag] = useUpdateUserTagMutation();
  const [postToSlack] = usePostToSlackMutation();
  const [postToMSTeams] = usePostToMSTeamsMutation();

  const [dropdownAllotment, setDropdownAllotment] = useState<AllotmentDropdownOption>();
  useEffect(() => {
    setDropdownAllotment(allotmentOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allotments]);

  function handleDropdownChange(allotment: AllotmentDropdownOption) {
    scrollToCardOnSelect(allotment, allotments);
    setDropdownAllotment(allotment);
  }

  if (companyId && checkHelpScoutMessages) {
    const helpScout = new HelpScout();
    helpScout.showForcedHelpScoutMessages({
      companyId,
      type: 'path',
      path: '/',
    });

    setCheckHelpScoutMessages(false);
  }

  const _setOpen = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      setSelectedCategory(undefined);
      setSelectedAllotment(undefined);
      setSearchParams({});
    }
  };

  const showNoFundsToast = () => {
    triggerCustomToast('error', 'There are no funds remaining for that perk category and stipend');
  };

  const _setOpenSelectCategorySidePanel = (isOpen: boolean, allotment?: Allotment) => {
    // allotment is not passed when called from the 'Submit a Claim' button
    if (allotment) {
      if (allotment.balance_amount === 0) {
        showNoFundsToast();
        return;
      }
      setSelectedAllotment(allotment);
    } else {
      // reset to be sure we don't open the modal with an allotment
      setSelectedAllotment(undefined);
    }
    setOpenSelectCategorySidePanel(isOpen);
    if (!isOpen) {
      setSelectedCategory(undefined);
      setSelectedAllotment(undefined);
    }
  };

  const onCategoryClick = (category: PerkCategory, allotment: Allotment, callback?: () => void) => {
    if (allotment.balance_amount === 0) {
      showNoFundsToast();
      return;
    }

    if (callback) {
      callback();
    }

    setSelectedCategory(category);
    setSelectedAllotment(allotment);
    setOpen(true);
  };

  // This useEffect has an empty list of dependencies so it only triggers on-mount.
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // Clear params in the case of page reload from claim side panel.
    if (searchParams.has('category_id') && searchParams.has('stipend_id')) {
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navigate to the correct module based on permissions
  useEffect(() => {
    if (homePath === '/') return;
    navigate(homePath);
  }, []);

  if (!isStipendEligible) {
    return <Error404Page />;
  }

  if (!allotments) {
    return <ComptLoadingIndicator isLoading={true} dependentData={false} className="h-full" />;
  }

  return (
    <>
      <ComptSelectCategorySidePanel
        title="Submit a claim"
        userCountryCode={userCountryCode}
        categoryCustomizations={categoryCustomizations}
        allotments={allotmentsWithBalance || []}
        setOpen={setOpenSelectCategorySidePanel}
        onSubmit={(category: PerkCategory) => {
          setSelectedCategory(category);
          setOpen(true);
        }}
        open={openSelectCategorySidePanel}
        selectedAllotment={selectedAllotment}
      />
      <ComptSaveClaimSidePanel
        title="Submit a claim"
        id="my-stipends-create-claim-side-panel"
        label="Create claim"
        open={open}
        setOpen={_setOpen}
        onSubmit={(payload) =>
          onSubmitClaim(
            payload,
            createStipend,
            updateUserTag,
            _setOpen,
            _setOpenSelectCategorySidePanel,
            postToSlack,
            postToMSTeams,
            hasSlackToken,
            customReimbursementInfo,
          )
        }
        allotments={allotmentsWithBalance ?? []}
        companyId={companyId}
        userCountryCode={userCountryCode}
        mutationLoading={isLoading}
        selectedCategory={selectedCategory}
        selectedAllotment={selectedAllotment}
        data-testid="compt-save-claim-side-panel"
        hasSlackToken={companyHasSlack && hasSlackToken}
        hasMsTeams={hasMsTeams}
      />
      {allotmentToDescribe && (
        <FullAllotmentDescriptionModal
          open={true}
          allotment={allotmentToDescribe}
          setOpen={() => setAllotmentToDescribe(null)}
        />
      )}
      {draftExpensesCount ? (
        <div className="my-6 mx-4 sm:mx-10 mb-6">
          <ComptWarningBanner
            messageComponent={
              <div className="flex gap-4">
                <p className="label2">{draftExpensesCount} Receipts pending submission</p>
                <p className="body1">Confirm submission to be reimbursed for these claims.</p>
              </div>
            }
            actionComponent={
              <ComptLink
                link="#/activity/draft-expenses"
                textClassName="text-color-link label2 items-center"
                className="flex items-center"
              >
                Review
              </ComptLink>
            }
          />
        </div>
      ) : (
        <></>
      )}
      <ComptPage
        title="My Stipends"
        subtitle="View or submit stipend claims"
        includeBottomHR={isMobileView}
        className="mb-24"
        action={
          !isMobileView && (
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              iconId={ComptButtonIcon.PLUS}
              onClick={() => _setOpenSelectCategorySidePanel(true)}
              disabled={allotments?.length === 0}
              data-testid="compt-stipend-expense-submit-claim-button"
            >
              Submit a claim
            </ComptButton>
          )
        }
      >
        <>
          <>
            <ComptRow className="flex sm:flex-row sm:space-x-400 flex-col space-y-1 sm:space-y-0">
              {stipendsStats &&
                Object.entries(stipendsStats).map(([key, value]) => {
                  if (key === 'stipends_available' && isMobileView) {
                    return null;
                  }

                  return (
                    <StipendStatCard
                      key={key}
                      title={value.title}
                      stat={value.stat}
                      helpText={value.help_text}
                      userCountryCode={userCountryCode}
                      doodleName={value.doodle_name}
                    />
                  );
                })}
            </ComptRow>
            {allotments?.length > 0 ? (
              <ComptRow
                className="flex flex-row items-baseline justify-between
                h-000 sm:h-600 mt-600 sm:mt-000 !mb-400 pb-400 sm:pb-000"
              >
                <h1 className="text-color-body1">Available stipends</h1>
                {!isMobileView && (
                  <div className="flex flex-row items-baseline justify-end">
                    <p className="body2 text-color-body1 mr-300">Jump to:</p>
                    <ComptDropDown
                      id="allotment-dropdown"
                      getDisplayText={(allotmentOption) => allotmentOption.allotment_dropdown}
                      getKey={(allotmentOption) => allotmentOption.id}
                      options={allotmentOptions}
                      value={dropdownAllotment}
                      onChange={handleDropdownChange}
                      alignRight={true}
                      by="id"
                      additionalClasses="w-96"
                    />
                  </div>
                )}
              </ComptRow>
            ) : null}
          </>
          <div className="mb-800 sm:mb-0">
            {allotments?.length > 0 ? (
              allotments?.map((allotment: Allotment, i: number) => (
                <ComptRow className="!mb-400" key={i} id={`allotment-${allotment.id}`}>
                  {!isMobileView && (
                    <AllotmentCard
                      allotment={allotment}
                      categoryCustomizations={categoryCustomizations}
                      userCountryCode={userCountryCode}
                      onFullDescriptionClicked={(allotment) => setAllotmentToDescribe(allotment)}
                      onCategoryClick={onCategoryClick}
                      setSelectedCategory={setSelectedCategory}
                      setSelectedAllotment={setSelectedAllotment}
                    />
                  )}
                  {isMobileView && (
                    <MobileAllotmentCard
                      allotment={allotment}
                      categoryCustomizations={categoryCustomizations}
                      userCountryCode={userCountryCode}
                      onCategoryClick={onCategoryClick}
                    />
                  )}
                </ComptRow>
              ))
            ) : (
              <NoPermissionsPage
                heading="Looks like you don't have a stipend yet or may not be eligible"
                description={
                  'We recommend reaching out to your HR team for any questions or help getting set up!'
                }
              />
            )}
          </div>
        </>
      </ComptPage>
      {isMobileView && (
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform
          -translate-x-1/2 w-full p-300 border-t border-t-gray-300`}
        >
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.PLUS}
            onClick={() => _setOpenSelectCategorySidePanel(true)}
            disabled={allotments?.length === 0}
            data-testid="compt-stipend-expense-submit-claim-button"
            className="w-full"
          >
            Submit a Claim
          </ComptButton>
        </div>
      )}
    </>
  );
};
