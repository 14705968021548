import React from 'react';

// Hooks and methods
import {formatCurrency} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';
import {StatusFormats, statusMap} from '@compt/types/stipend-expenses/stipend-expenses';

// Components
import {Tooltip} from 'react-tooltip';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

export class ReviewStipendTableController {
  static getColumnOptions() {
    return [
      {id: '', label: 'All columns'},
      {id: 'full_name', label: 'Full name'},
      {id: 'user__email', label: 'Email'},
      {id: 'user__first_name', label: 'First name'},
      {id: 'user__last_name', label: 'Last name'},
      {id: 'cycle_name', label: 'Stipend'},
      {id: 'name_of_vendor', label: 'Vendor'},
      {id: 'perk_category_name', label: 'Perk category'},
      {id: 'date_of_expense', label: 'Date of expense'},
      {id: 'created_on', label: 'Date submitted'},
      {id: 'expense_status', label: 'Status'},
      {id: 'description', label: 'Description'},
    ];
  }

  static getOCRIcon(expense: ExpenseReview) {
    switch (expense.ocr_status) {
      case null:
        return <ComptSvgIcon iconName="eye-off-icon" svgProp={{width: '25px'}} />;
      case 'Processing':
        return <ComptSvgIcon iconName="gray-cycle-icon" svgProp={{width: '25px'}} />;
      case 'Flagged':
        // eslint-disable-next-line no-case-declarations
        let title = '';
        if (expense.ocr_amount_warning && expense.ocr_vendor_warning) {
          title = 'Amount and vendor mismatch';
        } else if (expense.ocr_amount_warning) {
          title = 'Amount mismatch';
        } else if (expense.ocr_vendor_warning) {
          title = 'Vendor mismatch';
        }
        return (
          <>
            <Tooltip
              anchorSelect={'#ocr-warning'}
              place="top"
              opacity={100}
              style={{borderRadius: '6px', zIndex: 100}}
            >
              {title}
            </Tooltip>
            <ComptSvgIcon id="ocr-warning" iconName="yellow-flag-icon" svgProp={{width: '25px'}} />
          </>
        );
      case 'Success':
        return <ComptSvgIcon iconName="circle-check-green-icon" svgProp={{width: '25px'}} />;
    }
  }

  static getColumnDefinition(
    onMenuClicked: (expense: ExpenseReview, action: 'EDIT' | 'VIEW' | 'APPROVE' | 'REJECT') => void,
  ) {
    const columnDefinition = {
      status: {
        id: 'status',
        name: 'Status',
        selector: (expense: ExpenseReview) => {
          const statusFormat = StatusFormats[statusMap(expense.status)];

          return (
            <ComptPill pillType={statusFormat?.statusStyle} data-tag="allowRowEvents">
              {statusFormat?.statusName}
            </ComptPill>
          );
        },
        grow: 0.3,
        width: '150px',
        order: 1,
        omit: false,
      },
      employee_name: {
        id: 'employee_name',
        name: 'Employee',
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">
            {expense?.last_name}, {expense?.first_name}
          </p>
        ),
        grow: 0.5,
        width: '250px',
        order: 2,
        omit: false,
      },
      ...(featureEnabled(FeatureFlags.EXPENSE_OCR) && {
        ocr_warning: {
          id: 'ocr_warning',
          name: 'Verification',
          selector: (expense: ExpenseReview) => <div>{this.getOCRIcon(expense)}</div>,
          width: '150px',
          grow: 0.4,
          order: 3,
          center: true,
        },
      }),
      country: {
        id: 'country',
        name: 'Country',
        selector: (expense: ExpenseReview) => <p data-tag="allowRowEvents">{expense?.country}</p>,
        width: '100px',
        grow: 0.3,
        order: 4,
        omit: false,
      },
      date_submitted: {
        id: 'date_submitted',
        label: 'Date submitted', // Used to generate show/hide column option when name is not a string
        name: (
          <>
            <Tooltip
              anchorSelect={'#date-submitted-label'}
              place="bottom"
              opacity={100}
              style={{borderRadius: '6px', zIndex: 100}}
            >
              The date the expense was submitted (in your HQ&apos;s time zone).
            </Tooltip>
            <div id="date-submitted-label" className="flex items-center gap-x-1">
              Date submitted
              <ComptSvgIcon
                iconName="help-circle"
                svgProp={{width: '16px', height: '16px'}}
                className="text-gray-500 cursor-pointer"
              />
            </div>
          </>
        ),
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.submitted_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        width: '200px',
        grow: 0.5,
        order: 5,
        omit: false,
      },
      amount_claimed: {
        id: 'amount_claimed',
        name: 'Amount',
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">
            {formatCurrency(expense?.amount_claimed, expense?.currency)}
          </p>
        ),
        width: '175px',
        grow: 0.5,
        order: 6,
        omit: false,
      },
      taxability: {
        id: 'taxability',
        name: 'Taxability',
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">{expense?.is_taxable ? 'Taxable' : 'Nontaxable'}</p>
        ),
        grow: 0.5,
        width: '175px',
        order: 7,
      },
      stipend_name: {
        id: 'stipend_name',
        name: 'Stipend',
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">{expense.stipend_name}</p>
        ),
        grow: 1,
        width: '300px',
        order: 8,
      },
      perk_category_name: {
        id: 'perk_category_name',
        name: 'Category',
        selector: (expense: ExpenseReview) => {
          if (typeof expense.perk_category === 'number') return <></>;
          return <p data-tag="allowRowEvents">{expense.perk_category?.name}</p>;
        },
        grow: 0.5,
        width: '250px',
        order: 9,
      },
      vendor_name: {
        id: 'vendor_name',
        name: 'Vendor',
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">{expense.vendor_name}</p>
        ),
        omit: false,
        grow: 0.5,
        width: '250px',
        order: 10,
      },
      description: {
        id: 'description',
        name: 'Description',
        grow: 1,
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">{expense.description}</p>
        ),
        omit: false,
        order: 11,
      },
      date_of_expense: {
        id: 'date_of_expense',
        name: 'Date of expense',
        grow: 0.4,
        selector: (expense: ExpenseReview) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.date_of_expense, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 12,
        width: '200px',
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expense: ExpenseReview) => {
          const actions: Array<SimpleAction<ExpenseReview>> = [
            {
              label: 'View',
              onClick: (expense) => onMenuClicked(expense, 'VIEW'),
            },
          ];

          return <SimpleActionMenu relatedActionItem={expense} actions={actions} />;
        },
        grow: 0,
        order: 13,
        disableRemoval: true,
      },
    };

    return columnDefinition;
  }
}
